@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.wrapper {
  max-width: 1600px;
  padding: 0 20px;
  margin: 0 auto;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.header {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &RightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  @include mqMaxWidth($screenS) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 10px;
  }
}

.title {
  font-size: 20px;
  font-weight: 400;
  margin: 0 10px 0 0;
  line-height: 25px;
  flex-shrink: 0;

  @include mqMaxWidth($screenS) {
    font-size: 18px;
    line-height: 23px;
  }
}

.searchInput {
  max-width: 470px;
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;

  @include mqMaxWidth($screenS) {
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.viewTypeButton {
  margin-left: 20px;

  &:hover {
    i {
      fill: $baseTextColor;
    }
  }

  i {
    margin: 0;
    fill: $secondaryColor;
  }

  &Active {
    i {
      fill: $baseTextColor;
    }
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.profilesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22px 20px;

  @include mqMaxWidth($screenM) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mqMaxWidth($screenS) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mqMaxWidth($screen568) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.profilesList {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.spinner {
  margin-top: 20px;
  margin-bottom: 6px;

  @include mqMaxWidth($screen568) {
    margin-top: 30px;
  }
}

.missingSomething {
  padding: 20px 0;

  @include mqMaxWidth($screen568) {
    padding: 14px 0;
  }
}

.profilesWrapper {
  position: relative;
}

.zeroCase {
  height: 45vh;
  max-height: 700px;
  min-height: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
