@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.button {
  background: linear-gradient(243deg, $firmColor2 1.41%, $firmColor 104.92%);
  color: #fff !important;
  border: 0;
  display: flex;
  align-items: center;
  max-width: 150px;
  margin: 0;
  font-weight: 500;
  padding: 0 21px;
  line-height: 34px;
  border-radius: 3px;
  height: 36px;

  &:hover {
    @extend .button;

    background:
      linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(243deg, $firmColor2 1.41%, $firmColor 104.92%);
  }

  &:disabled {
    @extend .button;

    background: $secondary2Color !important;
  }
}

.icon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
}
