@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/mixins/truncateTextLines.scss";

.profileGridType {
  width: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 130px;

  .logoWrapper {
    background: #fff;
    padding: 45px 0;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body {
    background-color: $secondary5Color;
    padding: 20px;
    width: 100%;
    overflow: hidden;
    max-height: 130px;
    position: absolute;
    bottom: 0;
    transition: max-height 0.1s;
  }

  .description {
    @include truncateTextLines(3);
    margin: 10px 0 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    white-space: pre-line;
  }

  .button {
    width: 100%;
    margin: 0;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    .body {
      max-height: 100%;
      transition: max-height 0.3s ease;
    }

    .button {
      opacity: 1;
    }

    .description {
      @include truncateTextLines(7);
    }

    @include mqMaxWidth($screen568) {
      .description {
        @include truncateTextLines(6);
      }
    }
  }

  @include mqMaxWidth($screen568) {
    .body {
      max-height: 141px;
    }

    .description {
      @include truncateTextLines(4);
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.13px;
    }
  }
}

.profileListType {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid $defaultTableBorderColor;

  &:last-child {
    border-bottom: 0;
  }

  .logoWrapper {
    flex-shrink: 0;
    border: 1px solid $defaultTableBorderColor;
    width: 138px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
  }

  .body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .description {
    @include truncateTextLines(2);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin: 5px 0 0;
  }

  .button {
    margin: 0 0 0 20px;
    opacity: 0;
    transition: 0.1s;
  }

  &:hover {
    background-color: $defaultTableBorderColor;

    .logoWrapper {
      border-color: $secondaryColor;
    }

    .button {
      opacity: 1;
    }
  }
}

.companyName {
  @include truncateTextLines(3);
  color: $titleColor;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.logo {
  margin: 0 auto;
  display: block;
  max-height: 80px;
  max-width: 150px;
  width: auto;
}

