@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.loginButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  padding: 0 20px;
  margin: 0;

  @include mqMaxWidth($screenXs) {
    width: 79px;
    padding: 0 18px;
  }
}

.lockIcon {
  fill: #fff;
  height: 12px;
  width: 12px;
  margin-left: 10px;
  flex-shrink: 0;
}
