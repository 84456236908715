@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.textarea {
  height: 108px;
  resize: none;
}

.nameInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.headerText {
  margin-bottom: 15px;

  h1 {
    font-family: $titleFontFamily;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
    letter-spacing: -0.02em;
    max-width: 360px;
  }

  p {
    font-family: $fontFamily;
    font-size: 14px;
    line-height: 18px;
  }

  .modalDescription {
    margin: 17px 0 0;
    color: $baseTextColor;
    letter-spacing: 0.01em;
  }
}

.recaptchaContainer {
  margin-top: 10px;

  :global(.grecaptcha-badge) {
    visibility: hidden;
  }
}
