@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

button.actionButton {
  @include mqMaxWidth($screenXs) {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
