@import "@/Framework/GlobalStyles/variables.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.pageHeader {
  width: 100%;
  padding: 10px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 73px;

  &.pageHeaderNoWrap {
    z-index: 1;
    position: relative;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

.mobileNoAccess {
  @include mqMaxWidth($screenXs) {
    padding-bottom: 20px;
    >* {
      display: none;
    }
  }
}

.pageHeaderTitle {
  margin: 10px 10px 0 0;
}

.pageHeaderButtons {
  margin: 10px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 15px;
}
