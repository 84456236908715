@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@/finsight/ui/components/static/StaticPageHeader/staticHeader.scss";

.container {
  position: relative;
  width: 100%;
  background-color: $defaultBackgroundColor;

  &.containerSticky {
    padding-top: 55px;
  }
}

.content {
  min-height: 100%;
  max-width: 1195px;
  font-family: $fontFamily;
  font-size: $baseFontSize;
  color: $baseTextColor;
  padding: 50px 20px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  word-break: break-word;

  @include mqMinMaxWidth($screenXXs, $screenM) {
    padding: 36px 20px;
  }

  @include mqMaxWidth($screenXs) {
    padding: 10px 10px 30px;
  }
}

.bulletList {
  margin: 5px 0 20px;
  padding-left: 15px;
  list-style: none;

  li {
    color: $baseTextColor;
    line-height: 25px;

    &:before {
      content: "\25A0";
      color: $secondaryColor;
      display: inline-block;
      width: 15px;
      margin-left: -15px;
    }
  }
}
