@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.container {
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 167px);
  padding: 30px 0 50px 30px;

  @include mqMaxWidth($screenM) {
    height: auto;
    padding: 30px;
  }

  @include mqMaxHeight($screenS) {
    height: auto;
  }

  @include mqMaxWidth($screenXs) {
    padding: 15px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  max-width: 1168px;

  &.isDoubleImage {
    max-width: 1302px;
  }

  @include mqMaxWidth($screenM) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  @include mqMaxWidth($screenL) {
    h1 {
      font-size: 24px;
    }
  }

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      @include mqMinMaxWidth($screenM + 1, $screenL) {
        margin-right: 40px;
      }

      @include mqMinMaxWidth($screenS, $screenM) {
        max-width: 722px;
      }

      @include mqMinWidth($screenL) {
        margin-right: 100px;
      }
    }

    &:last-child {
      position: relative;
      flex-shrink: 0;
      align-items: center;

      @include mqMinMaxWidth($screenM + 1, $screenL) {
        padding-left: 20px;
      }

      @include mqMinWidth($screenL) {
        padding-left: 80px;
      }

      @include mqMaxWidth($screenM) {
        max-width: 722px;

        img {
          width: 100%;
        }
      }

      @include mqMinWidth($screenM + 1) {
        &:before {
          content: '';
          display: block;
          height: 405px;
          width: 1px;
          background-color: $secondary2Color;
          position: absolute;
          left: 0;
          top: calc(50% - 200px);
        }
      }
    }
  }
}

.featureItem {
  display: flex;

  & + & {
    margin-top: 20px;
  }

  .text {
    color: $titleColor;
    font-size: 16px;
    font-style: normal;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.16px;

    @include mqMaxWidth($screenL) {
      font-size: 14px;
    }
  }
}

.icon {
  fill: $successColor;
  margin-right: 20px;
  margin-top: 5px;
  flex-shrink: 0;
}

.description {
  color: $titleColor;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px; /* 150% */
  white-space: pre-wrap;

  @include mqMaxWidth($screenL) {
    font-size: 14px;
  }
}

.caption {
  text-align: center;
  font-size: 12px;
  line-height: 17px; /* 141.667% */
  letter-spacing: 0.12px;
  margin: -10px 30px 0;

  @include mqMaxWidth($screenM) {
    display: none;
  }
}

button.actionButton {
  @include mqMaxWidth($screenXs) {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.desktopOnlyCaption {
  display: none;
  margin-top: 30px;
  padding: 8px 22px;
  background-color: $disclaimerColor;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  @include mqMaxWidth($screenM) {
    display: flex;
  }

  i {
    margin-right: 10px;

    svg {
      fill: $secondary3Color;
    }
  }
}

.buttonWrapper {
  margin-top: 30px;
}

.mobileVisible {
  display: flex;

  @include mqMaxWidth($screenM) {
    display: none;
  }
}
